.App
{
    text-align:         center;
    background-color:   #2F2734;
    width:              100%;
    position:           absolute !important;
    top:                0px;
    bottom:             0;
}

/* Utility */
.bg-color
{
    color: #2F2734;
}
.side-by-side
{
    display: flex;
}


.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* TAB LIST */
.tab-list>li
{
    display: inline-block;
    float: none;
    padding: 15px 30px;
    color: #FFF;
    background: #453D4B;
    cursor: pointer;
    border-radius: 5px;
    margin: 2px;
}
.tab:hover
{
    background-color:   #FFFFFF;
    color:              #453D4B;
    border-color:       #453D4B;
}
.tab-list .active
{
    background-color:   #FFFFFF !important;
    color:              #453D4B !important;
    border-color:       #453D4B !important;
}
.text>p
{
    color: rgb(248, 248, 247);
}
@media only screen and (max-width: 992px)
{
    .text>p
    {
        font-size: .8em;
    }
    .side-by-side
    {
        display: block;
    }
    .App
    {
        position: relative;
    }
}

.tab-list>ul
{
    padding: 0;
}
.tab-list
{
    display: inline-block !important;
}

.product-image-iPad
{
    width: 300px;
    height: 423.75px;
}
.product-image-iPhone
{
    width: 217.75px;
    height: 444px;
}
.space
{
    clear: both;
    height: 40px;
    width: 100%;
}

/* Icon */
.icon
{
    color: #cacbcc;
    margin-bottom: 5px;
    float: left;
}
.icon-bg-md
{
    width: 50px;
    height: 50px;
    font-size: 1.5em;
}
[class*=icon-bg-], [class*=icon-bg]
{
    border-radius: 50%;
    color: #FFF;
    margin: 0 auto;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
[class*=icon-bg]
{
    background: #fff;
}

@media only screen and (max-width: 992px)
{
    .text
    {
        margin-left: 65px;
    }
}

.text
{
    margin-left: 65px;
}
.text>h6
{
    color: #FFF;
    font-weight: 700;
}

.list
{
    cursor: default;
    padding: 10px;
}

/* Centered text */
.text-center
{
    text-align: center;
}
.text-left
{
    text-align: left;
}

/* Form validation */
.valid
{
    background:             url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/check.svg);
    background-size:        15px;
    background-repeat:      no-repeat;
    background-position:    6px 15px;
}

.invalid
{
    background: pink;
}

.requirement
{
    padding:    0 30px 0 50px;
    color:      #999;
    max-height: 0;
    transition: 0.28s;
    overflow:   hidden;
    color:      red;
    font-style: italic;
}

.hidden
{
    display: none;
}

.requirement-showing
{
    max-height: 200px;
    padding:    0 30px 20px 50px;
    color:      #999;
    transition: 0.28s;
    overflow:   hidden;
    color:      red;
    font-style: italic;
}

/* Image carousel */
.image-carousel-item
{
    background-color: transparent !important;
    cursor: pointer;
}

.carousel-indicators>li
{
    background-color: #2F2734 !important;
}


/* Carousel image modal (background) */
.carousel-image-modal
{
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 16; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

.carousel-image-modal-showing
{
    display: block !important;
}

/* Modal Content (Image) */
.carousel-image-modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Caption of Modal Image (Image Text) - Same Width as the Image */
#carousel-image-modal-content-caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation - Zoom in the Modal */
.carousel-image-modal-content, #carousel-image-modal-content-caption {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {transform:scale(0)}
  to {transform:scale(1)}
}

/* The Close Button */
.carousel-image-modal-close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.carousel-image-modal-close:hover,
.carousel-image-modal-close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
  .carousel-image-modal-content {
    width: 100%;
  }
}


/* Headers/Text styling */
h2
{
    color: rgb(248, 248, 247);
}

h2.title
{
    margin-top:     10px;
    text-align:     center !important;
}

p.sub-title
{
    font-size: 1.2em;
    color: rgb(248, 248, 247);
}

p
{
    color: rgb(248, 248, 247);
}

.header-text
{
    max-width: 600px;
    margin: 0 auto 60px auto;
}

/* Input field styling */
.form-validated input[type=text],
.form-validated input[type=email],
.form-validated input[type=number],
.form-validated input[type=file],
.form-validated select,
.form-validated textarea,
.form-validated input[type=password]
{
    width:         100%;
    padding:       12px 30px;
    border-color:  #DDDDDD;
    border-style:  solid;
    border-width:  1px;
    border-radius: 4px;
    box-sizing:    border-box;
    margin-top:    6px;
    margin-bottom: 16px;
    resize:        vertical;
}

/* Blue submit button */
input[type=submit]
{
    color:          #FFF !important;
    background:     #453D4B !important;
    border-color:   #453D4B !important;
    padding:        12px 20px;
    border-radius:  4px;
    cursor:         pointer;
    border-width:   0.5px;
    border-style:   solid;
}


/* Hover effect on buttons  */
input[type=submit]:hover:enabled
{
    background-color:   #FFFFFF;
    color:              #2F2734;
    border-color:       #2F2734;
}

input[type=submit]:hover:disabled
{
    cursor: not-allowed;
}

input[type=submit]:disabled
{
    opacity: 0.65;
}

/* Container with centered content */
.content-container
 {
     border-radius:     5px;
    /* background-color:  #F2F2F2;*/
     background-color:  rgb(248, 248, 247);
     padding:           20px;
     max-width:         420px;
     margin:            auto;
     margin-top:        40px;
     color:             #2F2734;
}

.content-container button
{
    color:          #FFF !important;
    background:     #453D4B !important;
    border-color:   #453D4B !important;
    border-radius:  5px;
}

.content-container button:hover:enabled
{
    cursor: pointer;
}

.content-container button:hover:disabled
{
    cursor: not-allowed;
}

/* Blue text */
.blue
{
    color: #0074D9;
}

.centered-overlay
{
    top:        20%;
    left:       50%;
    transform:  translate(-50%, -50%);
    position:   fixed !important;
    z-index:    100;
}

.alert
{
    max-width:  280px;
    margin:     auto;
}

.alert p
{
    color: inherit !important;
}

.btn-link
{
    font-weight: 500 !important;
}

.reset p, .form-validated p
{
    color:      #2F2734;
    text-align: initial;
}

/*************/
/*   Home    */
/*************/

.pane-holder
{
    margin-top: 0px;
    /*height:     94vh;*/
    max-width:  100%;
    display:    flex;
    flex-direction: row;
}

.left-pane
{
    text-align: left;
    width:  fit-content;
    float: left;
    display:flex;
    flex-direction:column;
}

.center-pane
{
    display:flex;
    margin-left:5%;
    margin-right: 5%;
    margin-top: 2%;
    flex-direction:column;
    width: 100%;
    border-radius: 4px;
}

.center-pane .row
{
    margin-left:    0px;
    margin-top:     20px;
}

.pages
{
    margin-top:     30px;
    margin-left:    50px;
}

.pages h2
{
    color: black;
}

.table .action:hover
{
    cursor: pointer;
}

.table .action
{
    color: #007bff;
}


/*************/
/* USERS */
/*************/
.table-root
{
  width: '100%';
  margin-top: 0;
  padding: 24;
}

.table-inner
{
    width: '100%';
    margin-bottom: 0;
}

.table-wrapper
{
  overflow-x: 'auto';
}

.table
{
  min-width: 750;
}

.image-cropper
{
    width: 55px;
    height: 55px;
    position: relative;
    overflow: hidden;
    border-radius: 0%;
}
.profile-pic
{
  display: inline;
  margin: 0 auto;
  margin-left: -25%;
  height: 100%;
  width: auto;
}

.visually-hidden
{
  border: 0;
  clip: 'rect(0 0 0 0)';
  height: 1;
  margin: -1;
  overflow: 'hidden';
  padding: 0;
  position: 'absolute';
  top: 20;
  width: 1;
}
.modal-content h2, p
{
  color: black;
}

.modal-content
{
  max-width: 700px;
}

.home
{
  background-color:   #FFF !important;
  height: 100%;
}


/*************/
/*   Item Grid    */
/*************/
.item-cell
{
  height: 140;
  width: 100;
  margin: 10px;
}

.enhanced-grid
{
   flexGrow: 1;
   max-width: 800px;
   margin-top: 40px;

}

.item-image
{
  height: 100px;
  width: 100px;
  margin: 5px;
}
