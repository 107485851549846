.coming-soon>h1,h2,h3,h4,h5,h6
{
	/*text-transform: uppercase;*/
	 -webkit-font-smoothing: antialiased;
	font-weight: 700 !important;
	color: #FFF;
	font-family: lato,sans-serif;
	width: 100%;
	text-align: initial;
}

/* Container with centered content */
.coming-soon .content-container
 {
     max-width:         720px;
     margin:            auto;
     margin-top:        40px;
	 background-color:  transparent;
}

.coming-soon label
{
	color: #FFF;
}

.coming-soon form
{
	margin-top: 20px;
}

.coming-soon form .valid
{
	background: #fff;
}
